import React from "react"
import "./style.scss"
import { navigate } from "gatsby"

function Pagination(props) {
	const currentPage = props.currentPage
	const maxPages = props.maxPages
	const slug = props.slug
	//const hash = props.hash

	// Monitor changes in back/forward browser buttons
	//const url = typeof document !== 'undefined' ? document.location : 'https://gatsby.org';
	// let params = (new URL(url)).searchParams
	// let page = parseInt(params.get("page"))
	// if (!page && url.pathname === '/news/') { page = 1 }
	// useLayoutEffect(() => {
	// 	if(page !== currentPage && page) {
	// 		setCurrentPage(page)
	// 	}
	// },[page])

	// useLayoutEffect(() => {
	// 	if(currentPage != 1 ) {
	// 		console.log('layoutef')
	// 		let element = document.getElementById(hash.substr(1))
	// 		if(element) {
	// 			let hashTop = element.offsetTop
	// 			window.setTimeout(
	// 			  () => window.scrollTo(0,hashTop),
	// 			  100
	// 			)
	// 		}
	// 	}
	// },[])

	// Prep pagination array for mapping
	let paginationArray = []
	const leftOfCurrent = 2
	const rightOfCurrent = 2
	
	for (let i = currentPage - leftOfCurrent; i - leftOfCurrent < currentPage + rightOfCurrent - 1; i++) {
		if(i > 0 && i <= maxPages) {
			paginationArray.push({i:i,currentPage:currentPage})
		}
	}

	// Map and build pagination links
  const buttonItems = paginationArray.map(prepPaginationLinks)
	function prepPaginationLinks(item) {
		return (
			<button key={"pagination-link" + item.i} className={["btn-paginate", item.i === currentPage ? "current" : null].join(" ")} onClick={() => navigate(`/` + slug + `/` + item.i)}>{item.i}</button>
		)
	}
  
  return (
    <section className="pagination">
      <button className={["btn-prevnext", currentPage > 1 ? null : "disabled"].join(" ")} onClick={() => currentPage >  1 ? navigate(`/` + slug + `/` +  (currentPage - 1)) : null}>Prev</button>
			<div className="btn-wrap">
				{buttonItems}
			</div>
      <button className={["btn-prevnext", currentPage < maxPages ? null : "disabled"].join(" ")} onClick={() => currentPage < maxPages ? navigate(`/` + slug + `/` +  (currentPage + 1)) : null}>Next</button>
    </section>
  )
}

export default Pagination