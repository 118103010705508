import React, {memo} from "react"
import "./style.scss"

import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Intro from "../../../animations/Intro"

function Tile(props) {
	const post = props.node
	const date = new Date(post?.publishDate)

	const baseDelay = props.baseDelay ? props.baseDelay : 0
	
  return (
		<Intro visible={true} in={{ border: 500, appear: 0 }} delayIn={baseDelay} stay={false} className="tile c0 border-c5 border-top-off border-left-off t">
			<article className="tile-inner">
				{ post?.heroImage || props.defaultImage ?
					<Intro visible={true} in={{ border: 500, bg: 500, fade: 500 }} delayIn={baseDelay + 500} stay={false} className="c5 t border-c5 image">
						<Link to={`/news/${post.slug}/`} className="nostrike">
							<GatsbyImage
								image={post?.heroImage?.gatsbyImageData ? post?.heroImage?.gatsbyImageData : props.defaultImage.gatsbyImageData}
								imgStyle={{objectFit: 'cover'}}
								objectPosition='50% 50%'
                title={post?.heroImage?.title}
								alt={post?.heroImage?.description}
							/>
						</Link>
					</Intro>
				: null }
				<Intro visible={true} in={{ fadeBottom: 500 }} delayIn={baseDelay + 500} stay={false} className="description c0 border-c5">
					{ post?.category ? <h6>{post?.category[0].title}</h6> : null }
					{ date ? <h6 style={{marginTop: "-.5em"}}>{date.toLocaleDateString(['en-US'], {month: 'numeric', day: 'numeric', year: 'numeric'})}</h6> : null }
					<Link to={`/news/${post?.slug}/`} className="post-title"><h2>{post?.title}</h2></Link>
					{ post?.description ? post?.description.description : null }
				</Intro>
				<div className="button c0 t">
					<Intro visible={true} in={{ border: 500, bg: 500, fade: 500 }} delayIn={baseDelay + 500} stay={false} className="button-inner c5 t border-c5">
						<Link to={`/news/${post?.slug}/`} className="btn no-effect"><span>Read More</span></Link>
					</Intro>
				</div>
			</article>
		</Intro>
  )
}

export default memo(Tile)