import React from 'react'
import './style.scss'
import Seo from '../../components/seo'

import MainFrameCover from '../../components/MainFrameCover'
import Footer from '../../components/Footer'

import Modal from '../../components/Modal'
import LoginBox from '../../components/Blocks/LoginBox'
import SubscribeBox from '../../components/Blocks/SubscribeBox'

import { useStaticQuery, graphql } from 'gatsby'
import Pagination from './Pagination'
import Tile from './Tile'
import Intro from '../../animations/Intro'
import { Link } from 'gatsby'

function Posts(props) {

  const data = useStaticQuery(graphql`query PostsQuery {
  allContentfulBlogPost(sort: {fields: [publishDate], order: DESC}) {
    edges {
      node {
        id
        title
        slug
        publishDate
        category {
          title
        }
        description {
          description
        }
        heroImage {
          gatsbyImageData(
            quality: 80
            layout: CONSTRAINED
            formats: WEBP
            breakpoints: [300, 500, 750, 1080, 1366, 1920]
          )
          title
          description
        }
      }
    }
  }
  contentfulOptions {
    defaultPressRoomImage {
      gatsbyImageData(
        quality: 70
        layout: CONSTRAINED
        breakpoints: [300, 500, 750, 1080, 1366, 1920]
        formats: WEBP
      )
      title
      description
    }
  }
}`)


  const currentPage = props?.pageContext?.currentPage
  const postsPerPage = props?.pageContext?.postsPerPage //parseInt(props.postsPerPage)
  const skip = props?.pageContext?.skip //postsPerPage * (parseInt(currentPage) - 1)
  const skap = skip + postsPerPage
  const maxPages = props?.pageContext?.numPages //Math.ceil(data.allContentfulBlogPost.edges.length / postsPerPage)
  const postItems = data?.allContentfulBlogPost?.edges?.slice(skip, skap).map(prepPosts)
  const postSlug = props?.pageContext?.slug

  function prepPosts(item, i) {
    const defaultImage = data?.contentfulOptions?.defaultPressRoomImage
    return (
      <Tile key={item.node.id} defaultImage={defaultImage} {...item} baseDelay={i * 125} />
    )
  }

  return (
    <>
      <MainFrameCover />

      <Modal trigger={"#modal-1st-login"}>
        <LoginBox />
      </Modal>

      <Modal trigger={"#modal-1st-subscribe"}>
        <SubscribeBox />
      </Modal>

      <main id="mainframe" className={`c0 layer active main-${props?.pageContext?.slug === "/" ? "frontpage" : props?.pageContext?.slug}`}>

        <Seo title={'Press Room and News'} description={"Find out what's new at Gulfstream Park here on our News page."} path={props.location?.pathname ? props.location?.pathname : ''} />

        <section id="posts" className="posts">
          <Intro key={"posts-page-" + currentPage} switch={currentPage} in={{ fade: 200 }} delayIn={0} out={{ fade: 200 }} className="posts-grid">
            <Link to="/racing-office/#downloads" class="btn padd-quarter"><span>media kit</span></Link>
            <div className="posts-grid">
              {postItems}
            </div>
          </Intro>
          {/* <pre>
            <code>{JSON.stringify(props.pageContext, null, 2)}</code>
          </pre> */}
        </section>
        <Pagination currentPage={currentPage} slug={postSlug} maxPages={maxPages} />

        <Footer />

      </main>
    </>
  )
}

export default Posts